'use client';

import NextError from 'next/error';

export default function GlobalError({
  params: { locale },
}: {
  readonly params: { locale: string };
}) {
  return (
    <html lang={locale}>
      <body>
        {/* biome-ignore lint/suspicious/noExplicitAny: Error is handled by Next.js */}
        <NextError statusCode={undefined as any} />
      </body>
    </html>
  );
}
